import {
    Alignment,
    Button,
    Classes,
    HTMLSelect,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    Popover,
    Position,
    Switch,
} from '@blueprintjs/core'
import firebase from 'firebase/app'
import _ from 'lodash'
import moment from 'moment'
import React, { useContext } from 'react'
// import { PlaidLinkOptions } from 'react-plaid-link'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { ClassificationTypes, timePeriodConfigurations } from '../models'
import { MainContext } from '../screens/Main'

const AppNavbar = styled(Navbar)`
    background-color: ${({ theme }) => theme.colors.menuBar} !important;

    .app-title {
        font-weight: 700;
        font-size: 20px;
        .nw {
        }
        .not-nw {
            color: #ffffff66;
        }
    }
    .update-times {
        padding-right: 10px;
        .updated {
            color: #ffffff66;
            font-size: 12px;
            font-weight: 400;
            padding-top: 3px;
        }
    }
    .bp3-navbar-heading {
        .nw {
            font-weight: 700;
        }
        .not-nw {
            font-weight: 700;
            color: #ffffff66;
        }
    }
    .plaid-button {
        button {
            // border: none !important;
            // background-color: transparent !important;
            // color: white !important;
        }
    }
`

const ToggleMenuItem = styled.div`
    margin-left: 10px;
`

const SelectMenuItem = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .label {
        flex: 1;
        padding-right: 30px;
    }
`

const SettingsMenu = styled(Menu)`
    .bp3-menu-header {
        .updated {
            color: #ffffff66;
            font-size: 12px;
            font-weight: 400;
            padding-top: 3px;
        }
    }

    .heading {
        padding-bottom: 8px;
    }
`

const Menubar: React.FC<RouteComponentProps> = (props) => {
    const { location, history: routerHistory } = props
    const context = useContext(MainContext)
    // const [linkToken, setLinkToken] = useState('')

    // useEffect(() => {
    //     const getPlaidLinkToken = firebase.functions().httpsCallable('getPlaidLinkToken')
    //     getPlaidLinkToken().then(
    //         (result) => {
    //             const linkToken: string = result.data
    //             console.log('got link token', linkToken)
    //             setLinkToken(result.data)
    //         },
    //         (err) => {
    //             console.error('failed to retrieve link token', err)
    //             setLinkToken('')
    //         },
    //     )
    // }, [])

    // plaid
    // const plaidOptions = useMemo((): PlaidLinkOptions => {
    //     return {
    //         token: linkToken,
    //         onSuccess: (publicToken, metadata) => {
    //             console.log('publicToken', publicToken)
    //             console.log('metadata', metadata)
    //             const exchangePlaidPublicToken = firebase
    //                 .functions()
    //                 .httpsCallable('exchangePlaidPublicToken')
    //             exchangePlaidPublicToken({
    //                 publicToken,
    //             }).then(
    //                 () => {
    //                     console.log('token exchanged')
    //                 },
    //                 (err) => {
    //                     console.error('failed to exchange token', err)
    //                 },
    //             )
    //         },
    //         onExit: (error, metadata) => {
    //             console.log('error', error)
    //             console.log('metadata', metadata)
    //         },
    //     }
    // }, [linkToken])
    // const { open: openPlaidLink, ready: isPlaidReady } = usePlaidLink(plaidOptions)

    const {
        config,
        updateConfig,
        // currents,
        // currentsLoading,
        // refreshCurrents,
        history,
        historyLoading,
        refreshHistory,
        quotes,
        quotesLoading,
        refreshQuotes,
        holdings,
        holdingsLoading,
        refreshHoldings,
    } = context

    if (!config) {
        return null
    }

    const currentConfig = config!
    const ytdMonths =
        Math.abs(
            moment(currentConfig.endingDate)
                .startOf('y')
                .add(-1, 'M')
                .diff(moment(currentConfig.endingDate), 'M'),
        ) + 1

    const Groupings = () => {
        return (
            <>
                <MenuDivider title="Grouping" className="heading" />
                <SelectMenuItem>
                    <div className="label">Group by</div>
                    <HTMLSelect
                        value={currentConfig.selectedAggregationKey}
                        className="select"
                        options={[
                            {
                                label: 'Account Type',
                                value: 'type',
                            },
                            {
                                label: 'Institution',
                                value: 'institution',
                            },
                            {
                                label: 'Classification',
                                value: 'classification',
                            },
                        ]}
                        onChange={(event) => {
                            updateConfig({
                                selectedAggregationKey: event.currentTarget
                                    .value as ClassificationTypes,
                            })
                        }}
                    ></HTMLSelect>
                </SelectMenuItem>
            </>
        )
    }

    const Periods = () => {
        return (
            <>
                <MenuDivider title="Periods" className="heading" />
                {_.range(0, 4).map((index) => {
                    const options = timePeriodConfigurations.map((period) => ({
                        label: period.label,
                        value: period.label,
                    }))
                    if (index) {
                        options.splice(0, 0, { label: '-', value: '' })
                    }
                    return (
                        <SelectMenuItem key={index}>
                            <div className="label">G/L Period {index + 1}</div>
                            <HTMLSelect
                                value={
                                    currentConfig.selectedTimePeriodLabels[index] || ''
                                }
                                className="select"
                                options={options}
                                onChange={(event) => {
                                    const newConfig = {
                                        ...currentConfig,
                                    }
                                    newConfig.selectedTimePeriodLabels[index] =
                                        event.currentTarget.value
                                    newConfig.selectedTimePeriodLabels = _.compact(
                                        newConfig.selectedTimePeriodLabels,
                                    )
                                    updateConfig(newConfig)
                                }}
                            ></HTMLSelect>
                        </SelectMenuItem>
                    )
                })}
                <SelectMenuItem>
                    <div className="label">History Period</div>
                    <HTMLSelect
                        value={currentConfig.visibleMonthCount}
                        className="select"
                        options={_.range(1, 37).map((v) => ({
                            label: `${v} mo ${v === ytdMonths ? ' (YTD)' : ''}`,
                            value: v,
                        }))}
                        onChange={(event) => {
                            updateConfig({
                                visibleMonthCount: parseInt(event.currentTarget.value),
                            })
                        }}
                    ></HTMLSelect>
                </SelectMenuItem>
            </>
        )
    }

    const Toggles = () => {
        return (
            <>
                <MenuDivider title="Options" className="heading" />
                <ToggleMenuItem>
                    <Switch
                        checked={!currentConfig.noGainLossOnCash}
                        inline={true}
                        label="G/L on Cash"
                        onChange={() => {
                            updateConfig({
                                noGainLossOnCash: !currentConfig.noGainLossOnCash,
                            })
                        }}
                    />
                </ToggleMenuItem>
                <ToggleMenuItem>
                    <Switch
                        checked={!currentConfig.noGainLossOnLiabilities}
                        inline={true}
                        label="G/L on Liabilities"
                        onChange={() => {
                            updateConfig({
                                noGainLossOnLiabilities:
                                    !currentConfig.noGainLossOnLiabilities,
                            })
                        }}
                    />
                </ToggleMenuItem>
                <ToggleMenuItem>
                    <Switch
                        checked={!currentConfig.noGainLossOnProperty}
                        inline={true}
                        label="G/L on Property"
                        onChange={() => {
                            updateConfig({
                                noGainLossOnProperty: !currentConfig.noGainLossOnProperty,
                            })
                        }}
                    />
                </ToggleMenuItem>
                <ToggleMenuItem>
                    <Switch
                        checked={currentConfig.showSingularCategoryAccounts}
                        inline={true}
                        label="Singular Accounts"
                        onChange={() => {
                            updateConfig({
                                showSingularCategoryAccounts:
                                    !currentConfig.showSingularCategoryAccounts,
                            })
                        }}
                    />
                </ToggleMenuItem>
                <ToggleMenuItem>
                    <Switch
                        checked={currentConfig.aggregatesOnly}
                        inline={true}
                        label="Aggregates Only"
                        onChange={() => {
                            updateConfig({
                                aggregatesOnly: !currentConfig.aggregatesOnly,
                            })
                        }}
                    />
                </ToggleMenuItem>
                <ToggleMenuItem>
                    <Switch
                        checked={currentConfig.showCents}
                        inline={true}
                        label="Cents"
                        onChange={() => {
                            updateConfig({
                                showCents: !currentConfig.showCents,
                            })
                        }}
                    />
                </ToggleMenuItem>
                <ToggleMenuItem>
                    <Switch
                        checked={currentConfig.showZeroBalanceAccounts}
                        inline={true}
                        label="Zero Balances"
                        onChange={() => {
                            updateConfig({
                                showZeroBalanceAccounts:
                                    !currentConfig.showZeroBalanceAccounts,
                            })
                        }}
                    />
                </ToggleMenuItem>
                <ToggleMenuItem>
                    <Switch
                        checked={currentConfig.reverseMonthOrder}
                        inline={true}
                        label="Reverse"
                        onChange={() => {
                            updateConfig({
                                reverseMonthOrder: !currentConfig.reverseMonthOrder,
                            })
                        }}
                    />
                </ToggleMenuItem>
            </>
        )
    }

    return (
        <AppNavbar fixedToTop={true}>
            <Navbar.Group className="nav-links" align={Alignment.LEFT}>
                <Navbar.Heading className="app-title">
                    <span className="nw">NW</span> <span className="not-nw">Portal</span>
                </Navbar.Heading>
                <Navbar.Divider />
                <Button
                    className={Classes.MINIMAL}
                    text="Dashboard"
                    intent={location.pathname === '/' ? 'primary' : 'none'}
                    onClick={() => {
                        routerHistory.push('/')
                    }}
                />
                <Button
                    className={Classes.MINIMAL}
                    text="History"
                    intent={location.pathname === '/history' ? 'primary' : 'none'}
                    onClick={() => {
                        routerHistory.push('/history')
                    }}
                />
                <Button
                    className={Classes.MINIMAL}
                    text="Rebalancing"
                    intent={location.pathname === '/rebalancing' ? 'primary' : 'none'}
                    onClick={() => {
                        routerHistory.push('/rebalancing')
                    }}
                />
                {/* <Button
                    className={Classes.MINIMAL}
                    text="Performance"
                    intent={location.pathname === '/performance' ? 'primary' : 'none'}
                    onClick={() => {
                        routerHistory.push('/performance')
                    }}
                /> */}
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
                <div className="update-times">
                    {/* {!!currents && currents.updated && (
                        <div className="updated">
                            Currents updated: {moment(currents.updated).format('LLL')}{' '}
                            {currentsLoading ? '(refreshing...)' : ''}
                        </div>
                    )} */}
                    {!!history && history.updated && (
                        <div className="updated">
                            History updated: {moment(history.updated).format('LLL')}{' '}
                            {historyLoading ? '(refreshing...)' : ''}
                        </div>
                    )}
                </div>
                {/* <Navbar.Divider />
                <div>
                    <Button
                        className={Classes.MINIMAL}
                        text="Link Account"
                        onClick={() => {
                            openPlaidLink()
                        }}
                        disabled={!isPlaidReady || !linkToken}
                    />
                </div> */}
                <Navbar.Divider />
                <Popover
                    portalClassName="action-menu"
                    content={
                        <SettingsMenu>
                            {/* <MenuDivider
                                title={
                                    <>
                                        <div>Current Balances</div>
                                        {!!currents && currents.updated && (
                                            <div className="updated">
                                                Updated:{' '}
                                                {moment(currents.updated).format('LLL')}
                                            </div>
                                        )}
                                    </>
                                }
                            />
                            <MenuItem
                                icon="refresh"
                                disabled={currentsLoading}
                                text={currentsLoading ? 'Refreshing...' : 'Refresh'}
                                onClick={() => {
                                    console.log('refreshing currents..')
                                    refreshCurrents().then(() => {
                                        console.log('refreshed currents')
                                    })
                                }}
                            /> */}
                            <MenuDivider
                                title={
                                    <>
                                        <div>Historical Balances</div>
                                        {!!history && history.updated && (
                                            <div className="updated">
                                                Updated:{' '}
                                                {moment(history.updated).format('LLL')}
                                            </div>
                                        )}
                                    </>
                                }
                            />
                            <MenuItem
                                icon="refresh"
                                disabled={historyLoading}
                                text={historyLoading ? 'Refreshing...' : 'Refresh'}
                                onClick={() => {
                                    console.log('refreshing history..')
                                    refreshHistory().then(() => {
                                        console.log('refreshed history')
                                    })
                                }}
                            />
                            <MenuDivider
                                title={
                                    <>
                                        <div>Quotes</div>
                                        {!!quotes && quotes.updated && (
                                            <div className="updated">
                                                Updated:{' '}
                                                {moment(quotes.updated).format('LLL')}
                                            </div>
                                        )}
                                        {(!quotes || !quotes.updated) && (
                                            <div className="updated">Never updated</div>
                                        )}
                                    </>
                                }
                            />
                            <MenuItem
                                icon="refresh"
                                disabled={quotesLoading}
                                text={quotesLoading ? 'Refreshing...' : 'Refresh'}
                                onClick={() => {
                                    console.log('refreshing quotes..')
                                    refreshQuotes().then(() => {
                                        console.log('refreshed quotes')
                                    })
                                }}
                            />
                            <MenuDivider
                                title={
                                    <>
                                        <div>Holdings</div>
                                        {!!holdings && holdings.updated && (
                                            <div className="updated">
                                                Updated:{' '}
                                                {moment(holdings.updated).format('LLL')}
                                            </div>
                                        )}
                                        {(!holdings || !holdings.updated) && (
                                            <div className="updated">Never updated</div>
                                        )}
                                    </>
                                }
                            />
                            <MenuItem
                                icon="refresh"
                                disabled={holdingsLoading}
                                text={holdingsLoading ? 'Refreshing...' : 'Refresh'}
                                onClick={() => {
                                    console.log('refreshing holdings..')
                                    refreshHoldings().then(() => {
                                        console.log('refreshed holdings')
                                    })
                                }}
                            />
                            {/* <MenuDivider
                                title={
                                    <>
                                        <div>Quote History</div>
                                    </>
                                }
                            />
                            <MenuItem
                                icon="refresh"
                                disabled={holdingsLoading}
                                text="Refresh"
                                onClick={() => {
                                    console.log('refreshing quote history...')
                                    const refreshQuoteHistory = firebase
                                        .functions()
                                        .httpsCallable('refreshQuoteHistory')
                                    return refreshQuoteHistory().then(
                                        (result) => {
                                            console.log('finished!')
                                        },
                                        (err) => {
                                            console.error(
                                                'failed to refresh quote history from API',
                                                err,
                                            )
                                        },
                                    )
                                }}
                            /> */}
                            {/* <MenuItem
                            icon="refresh"
                            disabled={holdingsLoading}
                            text="Adjust for Split"
                            onClick={() => {
                                console.log('adjusting for split...')
                                const adjustForSplit = firebase.functions().httpsCallable('adjustForSplit')
                                return adjustForSplit()
                                    .then(result => {
                                        console.log('finished!')
                                    }, err => {
                                        console.error('failed to adjust for split from API', err)
                                    })
                            }} /> */}
                        </SettingsMenu>
                    }
                    position={Position.BOTTOM_LEFT}
                >
                    <Button
                        className={Classes.MINIMAL}
                        icon="refresh"
                        text="Data Updates"
                    />
                </Popover>
                <Popover
                    portalClassName="action-menu"
                    content={
                        <SettingsMenu>
                            <Periods />
                            <Groupings />
                            <Toggles />
                        </SettingsMenu>
                    }
                    position={Position.BOTTOM_LEFT}
                >
                    <Button className={Classes.MINIMAL} icon="cog" text="Settings" />
                </Popover>
                <Navbar.Divider />
                <Button
                    className={Classes.MINIMAL}
                    icon="user"
                    text="Sign Out"
                    intent="danger"
                    onClick={() => {
                        firebase.app().auth().signOut()
                    }}
                />
            </Navbar.Group>
        </AppNavbar>
    )
}

export default withRouter(Menubar)

import numeral from 'numeral'
import React from 'react'

import { IRebalancingWorksheet, IRebalancingWorksheetValues } from '../../lib/rebalance'
import { Diff } from './Diff'
import { Percent } from './Percent'
import { Spacer } from './Spacer'

export interface IActualsProps {
    title: string
    worksheet: IRebalancingWorksheet
    values: IRebalancingWorksheetValues
}

export const Actuals: React.FC<IActualsProps> = (props) => {
    const { title, worksheet, values } = props
    return (
        <>
            <tr className={`centered overview bold ${title}`}>
                <td className="title" colSpan={999}>
                    <div>{title}</div>
                    <div className="value">{numeral(values.value).format('0,0.00')}</div>
                </td>
            </tr>
            <tr className={`centered overview bold ${title}`}>
                {/* <td colSpan={NUM_COLS_HOLDING + NUM_COLS_CURRENTS} rowSpan={8}></td>
            <td colSpan={NUM_COLS_REBALANCED} rowSpan={8} className="title">
                <div>{title}</div>
                <div className="value">{numeral(values.value).format('0,0.00')}</div>
            </td>   */}
                <Percent colSpan={9} value={values.percentsByAssetAllocation.usEquity} />
                <Percent value={values.percentsByAssetAllocation.internationalEquity} />
                <Percent value={values.percentsByAssetAllocation.fixedIncome} />
                <Percent value={values.percentsByAssetAllocation.cash} />
                <Percent value={values.percentsByAssetAllocation.other} />
            </tr>
            <tr className={`centered bold ${title}`}>
                <Percent
                    colSpan={3}
                    value={
                        values.percentsByEquityStyle.largeCap.growth +
                        values.percentsByEquityStyle.largeCap.blend +
                        values.percentsByEquityStyle.largeCap.value
                    }
                />
                <Percent
                    colSpan={3}
                    value={
                        values.percentsByEquityStyle.midCap.growth +
                        values.percentsByEquityStyle.midCap.blend +
                        values.percentsByEquityStyle.midCap.value
                    }
                />
                <Percent
                    colSpan={3}
                    value={
                        values.percentsByEquityStyle.smallCap.growth +
                        values.percentsByEquityStyle.smallCap.blend +
                        values.percentsByEquityStyle.smallCap.value
                    }
                />
                <Percent value={values.percentsByAssetAllocation.internationalEquity} />
                <Percent value={values.percentsByAssetAllocation.fixedIncome} />
                <Percent value={values.percentsByAssetAllocation.cash} />
                <Percent value={values.percentsByAssetAllocation.other} />
            </tr>
            <tr className={`centered ${title} labels`}>
                <td className="centered">Lg V</td>
                <td className="centered">Lg B</td>
                <td className="centered">Lg G</td>
                <td className="centered">Md V</td>
                <td className="centered">Md B</td>
                <td className="centered">Md G</td>
                <td className="centered">Sm V</td>
                <td className="centered">Sm B</td>
                <td className="centered">Sm G</td>
                <td className="centered">Int&apos;l</td>
                <td className="centered">Bonds</td>
                <td className="centered">Cash</td>
                <td className="centered">Other</td>
            </tr>
            <tr className={`centered ${title}`}>
                <Percent value={values.percentsByEquityStyle.largeCap.value} />
                <Percent value={values.percentsByEquityStyle.largeCap.blend} />
                <Percent value={values.percentsByEquityStyle.largeCap.growth} />
                <Percent value={values.percentsByEquityStyle.midCap.value} />
                <Percent value={values.percentsByEquityStyle.midCap.blend} />
                <Percent value={values.percentsByEquityStyle.midCap.growth} />
                <Percent value={values.percentsByEquityStyle.smallCap.value} />
                <Percent value={values.percentsByEquityStyle.smallCap.blend} />
                <Percent value={values.percentsByEquityStyle.smallCap.growth} />
                <Percent value={values.percentsByAssetAllocation.internationalEquity} />
                <Percent value={values.percentsByAssetAllocation.fixedIncome} />
                <Percent value={values.percentsByAssetAllocation.cash} />
                <Percent value={values.percentsByAssetAllocation.other} />
            </tr>
            <tr className={`centered ${title}`}>
                <td>
                    {numeral(values.valuesByEquityStyle.largeCap.value).format('0,0')}
                </td>
                <td>
                    {numeral(values.valuesByEquityStyle.largeCap.blend).format('0,0')}
                </td>
                <td>
                    {numeral(values.valuesByEquityStyle.largeCap.growth).format('0,0')}
                </td>
                <td>{numeral(values.valuesByEquityStyle.midCap.value).format('0,0')}</td>
                <td>{numeral(values.valuesByEquityStyle.midCap.blend).format('0,0')}</td>
                <td>{numeral(values.valuesByEquityStyle.midCap.growth).format('0,0')}</td>
                <td>
                    {numeral(values.valuesByEquityStyle.smallCap.value).format('0,0')}
                </td>
                <td>
                    {numeral(values.valuesByEquityStyle.smallCap.blend).format('0,0')}
                </td>
                <td>
                    {numeral(values.valuesByEquityStyle.smallCap.growth).format('0,0')}
                </td>
                <td>
                    {numeral(values.valuesByAssetAllocation.internationalEquity).format(
                        '0,0',
                    )}
                </td>
                <td>
                    {numeral(values.valuesByAssetAllocation.fixedIncome).format('0,0')}
                </td>
                <td>{numeral(values.valuesByAssetAllocation.cash).format('0,0')}</td>
                <td>{numeral(values.valuesByAssetAllocation.other).format('0,0')}</td>
            </tr>
            <Spacer />
            <tr className={`centered diff ${title}`}>
                <Diff
                    colSpan={9}
                    value={
                        values.percentsByAssetAllocation.usEquity -
                        worksheet.targetPercentByAssetAllocation.usEquity
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.internationalEquity -
                        worksheet.targetPercentByAssetAllocation.internationalEquity
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.fixedIncome -
                        worksheet.targetPercentByAssetAllocation.fixedIncome
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.cash -
                        worksheet.targetPercentByAssetAllocation.cash
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.other -
                        worksheet.targetPercentByAssetAllocation.other
                    }
                />
            </tr>
            <tr className={`centered diff ${title}`}>
                <Diff
                    colSpan={3}
                    value={
                        values.percentsByEquityStyle.largeCap.growth +
                        values.percentsByEquityStyle.largeCap.blend +
                        values.percentsByEquityStyle.largeCap.value -
                        (worksheet.targetPercentByEquityStyle.largeCap.growth +
                            worksheet.targetPercentByEquityStyle.largeCap.blend +
                            worksheet.targetPercentByEquityStyle.largeCap.value)
                    }
                />
                <Diff
                    colSpan={3}
                    value={
                        values.percentsByEquityStyle.midCap.growth +
                        values.percentsByEquityStyle.midCap.blend +
                        values.percentsByEquityStyle.midCap.value -
                        (worksheet.targetPercentByEquityStyle.midCap.growth +
                            worksheet.targetPercentByEquityStyle.midCap.blend +
                            worksheet.targetPercentByEquityStyle.midCap.value)
                    }
                />
                <Diff
                    colSpan={3}
                    value={
                        values.percentsByEquityStyle.smallCap.growth +
                        values.percentsByEquityStyle.smallCap.blend +
                        values.percentsByEquityStyle.smallCap.value -
                        (worksheet.targetPercentByEquityStyle.smallCap.growth +
                            worksheet.targetPercentByEquityStyle.smallCap.blend +
                            worksheet.targetPercentByEquityStyle.smallCap.value)
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.internationalEquity -
                        worksheet.targetPercentByAssetAllocation.internationalEquity
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.fixedIncome -
                        worksheet.targetPercentByAssetAllocation.fixedIncome
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.cash -
                        worksheet.targetPercentByAssetAllocation.cash
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.other -
                        worksheet.targetPercentByAssetAllocation.other
                    }
                />
            </tr>
            <tr className={`centered diff ${title}`}>
                <Diff
                    value={
                        values.percentsByEquityStyle.largeCap.value -
                        worksheet.targetPercentByEquityStyle.largeCap.value
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.largeCap.blend -
                        worksheet.targetPercentByEquityStyle.largeCap.blend
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.largeCap.growth -
                        worksheet.targetPercentByEquityStyle.largeCap.growth
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.midCap.value -
                        worksheet.targetPercentByEquityStyle.midCap.value
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.midCap.blend -
                        worksheet.targetPercentByEquityStyle.midCap.blend
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.midCap.growth -
                        worksheet.targetPercentByEquityStyle.midCap.growth
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.smallCap.value -
                        worksheet.targetPercentByEquityStyle.smallCap.value
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.smallCap.blend -
                        worksheet.targetPercentByEquityStyle.smallCap.blend
                    }
                />
                <Diff
                    value={
                        values.percentsByEquityStyle.smallCap.growth -
                        worksheet.targetPercentByEquityStyle.smallCap.growth
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.internationalEquity -
                        worksheet.targetPercentByAssetAllocation.internationalEquity
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.fixedIncome -
                        worksheet.targetPercentByAssetAllocation.fixedIncome
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.cash -
                        worksheet.targetPercentByAssetAllocation.cash
                    }
                />
                <Diff
                    value={
                        values.percentsByAssetAllocation.other -
                        worksheet.targetPercentByAssetAllocation.other
                    }
                />{' '}
            </tr>
        </>
    )
}

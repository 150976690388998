import React from 'react'

import { IRebalancingWorksheet } from '../../lib/rebalance'
import { Percent } from './Percent'

export interface ITargetsProps {
    worksheet: IRebalancingWorksheet
}

export const Targets: React.FC<ITargetsProps> = (props) => {
    const { worksheet } = props
    return (
        <>
            <tr className="overview centered bold targets">
                <td className="title" colSpan={999}>
                    Target
                </td>
            </tr>
            <tr className="overview centered bold targets">
                {/* <td colSpan={NUM_COLS_HOLDING + NUM_COLS_CURRENTS} rowSpan={3}></td>
            <td colSpan={NUM_COLS_REBALANCED} rowSpan={3} className="title">Target</td>   */}
                <Percent
                    colSpan={9}
                    value={worksheet.targetPercentByAssetAllocation.usEquity}
                />
                <Percent
                    value={worksheet.targetPercentByAssetAllocation.internationalEquity}
                />
                <Percent value={worksheet.targetPercentByAssetAllocation.fixedIncome} />
                <Percent value={worksheet.targetPercentByAssetAllocation.cash} />
                <Percent value={worksheet.targetPercentByAssetAllocation.other} />
            </tr>
            <tr className="centered targets bold">
                <Percent
                    colSpan={3}
                    value={
                        worksheet.targetPercentByEquityStyle.largeCap.growth +
                        worksheet.targetPercentByEquityStyle.largeCap.blend +
                        worksheet.targetPercentByEquityStyle.largeCap.value
                    }
                />
                <Percent
                    colSpan={3}
                    value={
                        worksheet.targetPercentByEquityStyle.midCap.growth +
                        worksheet.targetPercentByEquityStyle.midCap.blend +
                        worksheet.targetPercentByEquityStyle.midCap.value
                    }
                />
                <Percent
                    colSpan={3}
                    value={
                        worksheet.targetPercentByEquityStyle.smallCap.growth +
                        worksheet.targetPercentByEquityStyle.smallCap.blend +
                        worksheet.targetPercentByEquityStyle.smallCap.value
                    }
                />
                <Percent
                    value={worksheet.targetPercentByAssetAllocation.internationalEquity}
                />
                <Percent value={worksheet.targetPercentByAssetAllocation.fixedIncome} />
                <Percent value={worksheet.targetPercentByAssetAllocation.cash} />
                <Percent value={worksheet.targetPercentByAssetAllocation.other} />
            </tr>
            <tr className="centered targets labels">
                <td className="centered">Lg V</td>
                <td className="centered">Lg B</td>
                <td className="centered">Lg G</td>
                <td className="centered">Md V</td>
                <td className="centered">Md B</td>
                <td className="centered">Md G</td>
                <td className="centered">Sm V</td>
                <td className="centered">Sm B</td>
                <td className="centered">Sm G</td>
                <td className="centered">Int&apos;l</td>
                <td className="centered">Bonds</td>
                <td className="centered">Cash</td>
                <td className="centered">Other</td>
            </tr>
            <tr className="centered targets">
                <Percent value={worksheet.targetPercentByEquityStyle.largeCap.value} />
                <Percent value={worksheet.targetPercentByEquityStyle.largeCap.blend} />
                <Percent value={worksheet.targetPercentByEquityStyle.largeCap.growth} />
                <Percent value={worksheet.targetPercentByEquityStyle.midCap.value} />
                <Percent value={worksheet.targetPercentByEquityStyle.midCap.blend} />
                <Percent value={worksheet.targetPercentByEquityStyle.midCap.growth} />
                <Percent value={worksheet.targetPercentByEquityStyle.smallCap.value} />
                <Percent value={worksheet.targetPercentByEquityStyle.smallCap.blend} />
                <Percent value={worksheet.targetPercentByEquityStyle.smallCap.growth} />
                <Percent
                    value={worksheet.targetPercentByAssetAllocation.internationalEquity}
                />
                <Percent value={worksheet.targetPercentByAssetAllocation.fixedIncome} />
                <Percent value={worksheet.targetPercentByAssetAllocation.cash} />
                <Percent value={worksheet.targetPercentByAssetAllocation.other} />
            </tr>
        </>
    )
}
